<template>
    <div>
        <loading :active.sync="is_loading" :can-cancel="true" :is-full-page="true">
        </loading>
        <Navbar />
        <SidebarSistarum />
        <div id="main" class="main">
            <Breadcrumb :links="links" />
            <section class="section dashboard">
                <div class="row">
                    <div class="col-lg-12">
                        <h4><b>{{ detail.name }}</b></h4>
                        <ul class="nav nav-tabs">
                            <li class="nav-item">
                                <a class="nav-link" :class="current_tab == 'table' ? 'active':''" href="#" @click.prevent="current_tab = 'table'">Data telemetri</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" :class="current_tab == 'graph' ? 'active':''" href="#" @click.prevent="current_tab = 'graph'">Grafik</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" :class="current_tab == 'cctv' ? 'active':''" href="#" @click.prevent="current_tab = 'cctv'">CCTV</a>
                            </li>
                        </ul>
                        <div class="tab-content">
                            <div class="tab-pane p-3 bg-white" :class="current_tab == 'table' ? 'active':''" style="min-height: 350px">
                                <div class="row mb-3">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="">Tanggal mulai</label>
                                            <Datepicker v-model="date_start_tbl" input-class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="">Tanggal akhir</label>
                                            <Datepicker v-model="date_end_tbl" input-class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        &nbsp;<br />
                                        <button class="btn btn-primary" @click="() => searchTable(1)"><i class="fa fa-search"></i> cari</button>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <button class="btn btn-success" @click="downloadCsv"><i class="fa fa-download"></i> download CSV</button>
                                </div>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Timestamp</th>
                                            <th>TMA (m)</th>
                                            <th>Debit (m<sup>3</sup>/s)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="data in table.data" :key="data.id">
                                            <td>{{ formatDate(data.datetime) }}</td>
                                            <td>{{ data.tma }}</td>
                                            <td>{{ calcDebit(data.tma) }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <Paginate :totalPages="table.meta.pages" :currentPage="parseInt(table.meta.current_page)" @change-page="searchTable" />
                            </div>
                            <div class="tab-pane p-3 bg-white" :class="current_tab == 'graph' ? 'active':''" style="min-height: 350px">
                                <div class="row mb-3">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="">Tanggal mulai</label>
                                            <Datepicker v-model="date_start_graph" input-class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="">Tanggal akhir</label>
                                            <Datepicker v-model="date_end_graph" input-class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        &nbsp;<br />
                                        <button class="btn btn-primary" @click="() => searchGraph()"><i class="fa fa-search"></i> cari</button>
                                    </div>
                                </div>
                                <div class="card mb-3">
                                    <div class="card-body">
                                        <h4>Grafik Tinggi Muka Air</h4>
                                        <line-chart :options="tma_chart_options" :chart-data="tma_chart_data" v-if="current_tab == 'graph'"></line-chart>
                                    </div>
                                </div>
                                <div class="card mb-3">
                                    <div class="card-body">
                                        <h4>Grafik Debit Air</h4>
                                        <line-chart :options="debit_chart_options" :chart-data="debit_chart_data" v-if="current_tab == 'graph'"></line-chart>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane bg-white p-3" :class="current_tab == 'cctv' ? 'active':''" style="min-height: 350px">
                                <div class="row mb-3">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="">Tanggal mulai</label>
                                            <Datepicker v-model="date_start_cctv" input-class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="">Tanggal akhir</label>
                                            <Datepicker v-model="date_end_cctv" input-class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        &nbsp;<br />
                                        <button class="btn btn-primary" @click="() => searchCCTV()"><i class="fa fa-search"></i> cari</button>
                                    </div>
                                </div>
                                <img :src="currentImage.img_url" class="img-fluid" v-if="currentImage" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Breadcrumb from '../../components/Breadcrumb.vue'
import Navbar from '../../components/Navbar.vue'
import SidebarSistarum from '../../components/SidebarSistarum.vue'
import Paginate from '../../components/Paginate.vue'
import axios from 'axios'
import Datepicker from 'vuejs-datepicker';
import LineChart from '@/components/chart/LineChart'
import moment from 'moment';

export default {
    components: {
        Navbar,
        Loading, 
        Breadcrumb,
        SidebarSistarum,
        Datepicker,
        Paginate,
        LineChart
    },
    data(){
        return {
            is_loading: false,
            links: [
                {url: '/dashboard', text: 'Home'},
                {url: '/sistarum', text: 'Sistarum'},
                {url: '#', text: 'Detail'}
            ],
            detail: {},
            table: {
                data: {},
                meta: {}
            },
            images: [],
            current_tab: 'table',
            current_image_index: 0,
            date_start_tbl: new Date(),
            date_end_tbl: new Date(),
            date_start_graph: new Date(),
            date_end_graph: new Date(),
            date_start_cctv: new Date(),
            date_end_cctv: new Date(),
            tma_chart_options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [{
                        ticks: {
                            autoSkip: true,
                            maxTicksLimit: 6,
                            maxRotation: 0,
                            fontSize: 10
                        }
                    }],
                    yAxes: [{
                        type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        position: "left",
                        id: "y-axis-1",
                        scaleLabel: {
                            display: true,
                            labelString: "Tinggi Muka Air (m)"
                        },
                        ticks: {
                            // beginAtZero: true
                        }
                    }],
                },
                legend: {
                    labels: {
                        boxWidth: 12
                    }
                }
            },
            tma_chart_data: {},
            debit_chart_options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [{
                        ticks: {
                            autoSkip: true,
                            maxTicksLimit: 6,
                            maxRotation: 0,
                            fontSize: 10
                        }
                    }],
                    yAxes: [{
                        type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        position: "left",
                        id: "y-axis-1",
                        scaleLabel: {
                            display: true,
                            labelString: "Debit (m3/s)"
                        },
                        ticks: {
                            // beginAtZero: true
                        }
                    }],
                },
                legend: {
                    labels: {
                        boxWidth: 12
                    }
                }
            },
            debit_chart_data: {},
        }
    },
    mounted(){
        this.initData();
    },
    methods: {
        async initData(){
            const { id } = this.$route.params;
            this.is_loading = true;

            try {
                const requests = [
                    axios.get(`${this.$endpoints.sistarum}/locations/${id}/detail`),
                    axios.get(`${this.$endpoints.sistarum}/locations/${id}/table`),
                    axios.get(`${this.$endpoints.sistarum}/locations/${id}/graph`),
                    axios.get(`${this.$endpoints.sistarum}/locations/${id}/cctv`)
                ];

                const responses = await Promise.all(requests);
                const [detailData, tableData, graphData, cctvData] = responses.map((response) => response.data);

                this.detail = detailData;
                this.table = tableData;
                this.images = cctvData.map(img => {
                    img.img_url = `${this.$endpoints.sistarum}/locations/proxy?url=${img.img_url}`;

                    return img;
                });

                this.setupTMAChartData(graphData);
                this.setupDebitChartData(graphData);

                setInterval(() => {
                    this.current_image_index = (this.current_image_index + 1) % this.images.length;
                }, 10000);
            } catch (e) {
                console.log("error fetching data: ", e);
            }

            this.is_loading = false;

        },
        async searchTable(page){
            this.is_loading = true;

            const { id } = this.$route.params;

            try {
                const response = await axios.get(`${this.$endpoints.sistarum}/locations/${id}/table`, {
                    params: {
                        startDate: moment(this.date_start_tbl).format("YYYY-MM-DD"),
                        endDate: moment(this.date_end_tbl).format("YYYY-MM-DD"),
                        page
                    }
                });

                this.table = response.data;
            } catch (error) {
                console.log(error);
            }

            this.is_loading = false;
        },
        async searchGraph(){
            this.is_loading = true;

            const { id } = this.$route.params;

            try {
                const response = await axios.get(`${this.$endpoints.sistarum}/locations/${id}/graph`, {
                    params: {
                        startDate: moment(this.date_start_graph).format("YYYY-MM-DD"),
                        endDate: moment(this.date_end_graph).format("YYYY-MM-DD")
                    }
                });

                this.setupTMAChartData(response.data);
                this.setupDebitChartData(response.data);
            } catch (error) {
                console.log(error);
            }

            this.is_loading = false;
        },
        calcDebit(tma){
            const { k1, k2, k3 } = this.detail;
            
            return parseFloat((k1 * tma * tma) + (k2 * tma) + k3).toFixed(2);
        },
        formatDate(date){
            return moment(date).format("DD MMM YYYY, HH:mm:ss")
        },
        setupTMAChartData(data){
            let chartData = [];
            let labels = [];
            data.forEach(row => {
                labels.push(moment(row.datetime).format("DD MMM YYYY HH:mm"));
                chartData.push(parseFloat(row.tma));
            });

            this.tma_chart_data = {
                labels,
                datasets: [{
                    label: "Tinggi Muka Air",
                    borderColor: "#cc00ff",
                    borderWidth: 2,
                    backgroundColor: "transparent",
                    uom: null,
                    spanGaps: true,
                    lineTension: 0,
                    pointRadius: 3,
                    data: chartData
                }]
            }
        },
        setupDebitChartData(data){
            const { k1, k2, k3 } = this.detail;
            let chartData = [];
            let labels = [];
            data.forEach(row => {
                labels.push(moment(row.datetime).format("DD MMM YYYY HH:mm"));
                chartData.push(
                    parseFloat(
                        (k1 * row.tma * row.tma) + (k2 * row.tma) + k3
                    )
                );
            });

            this.debit_chart_data = {
                labels,
                datasets: [{
                    label: "Debit",
                    borderColor: "#2a7db0",
                    borderWidth: 2,
                    backgroundColor: "transparent",
                    uom: null,
                    spanGaps: true,
                    lineTension: 0,
                    pointRadius: 3,
                    data: chartData
                }]
            }
        },
        async searchCCTV(){
            this.is_loading = true;

            const { id } = this.$route.params;

            try {
                const response = await axios.get(`${this.$endpoints.sistarum}/locations/${id}/cctv`, {
                    params: {
                        startDate: moment(this.date_start_cctv).format("YYYY-MM-DD"),
                        endDate: moment(this.date_end_cctv).format("YYYY-MM-DD")
                    }
                });

                this.images = response.data.map(img => {
                    img.img_url = `${this.$endpoints.sistarum}/locations/proxy?url=${img.img_url}`;

                    return img;
                });
            } catch (error) {
                console.log(error);
            }

            this.is_loading = false;
        },
        sanitizeName(name) {
            // Replace invalid characters with an underscore or any desired replacement
            return name
                .replace(/[/\\?%*:|"<>]/g, '_') // Replace reserved characters
                .replace(/\s+/g, '_')          // Optionally replace spaces with underscores
                .replace(/[^\x20-\x7E]/g, '') // Remove Non-Printable Characters
                .trim();                       // Trim leading and trailing whitespace
        },
        arrayToCSV(array) {
            const csvRows = [];
            
            // Get headers from the first object keys
            const headers = Object.keys(array[0]).filter(k => k == 'tma' || k == 'datetime').reverse();
            headers.push('debit');
            csvRows.push(headers.join(',')); // join headers with commas

            // Loop through the array and create rows
            for (const row of array) {
                const values = headers.map(header => {
                    let escaped = ('' + row[header]).replace(/"/g, '\\"'); // escape double quotes
                    if(header == 'debit'){
                        return this.calcDebit(row['tma']) + ' m³/s';
                    }else if(header == 'datetime'){
                        return moment(row[header]).format("DD MMM YYYY, HH:mm:ss")
                    }

                    return `"${escaped} m"`; // wrap values in double quotes
                });
                csvRows.push(values.join(',')); // join values with commas
            }

            return csvRows.join('\n'); // join rows with new line
        },
        async downloadCsv(){
            const { id } = this.$route.params;
            let response;

            try {
                response = await axios.get(`${this.$endpoints.sistarum}/locations/${id}/table`, {
                    params: {
                        startDate: moment(this.date_start_tbl).format("YYYY-MM-DD"),
                        endDate: moment(this.date_end_tbl).format("YYYY-MM-DD"),
                        all: 1
                    }
                });
            } catch (error) {
                console.log(error);
            }

            const csvString = this.arrayToCSV(response.data.data);
            const blob = new Blob([csvString], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            const filename = `${this.sanitizeName(this.detail.name)}-${moment(this.date_start_tbl).format("YYYYMMDD")}-${moment(this.date_end_tbl).format("YYYYMMDD")}.csv`;

            a.setAttribute('hidden', '');
            a.setAttribute('href', url);
            a.setAttribute('download', filename);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    },
    computed: {
        currentImage() {
            return this.images[this.current_image_index];
        },
    }
}
</script>