<template>
    <div>
        <loading :active.sync="is_loading" 
        :can-cancel="true" 
        :is-full-page="true"></loading>
        <Navbar />
        <div class="container-fluid">
            <div id="main" class="main ms-0">
                <Breadcrumb :links="[{url: '/dashboard', text:'Home'}, {url: '/users/index', text: 'User'}, {url: '#', text:'List'}]"/>
                <h2><b>Edit Pengguna</b></h2>

                <div class="document">
                    <form>
                        <div class="row mb-3">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <label for="">Organisasi</label>
                                            <Select2 class="vue-select2" :options="organizations" @change="changeOrg" v-model="org_id" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Role</label>
                                    <select name="role" class="form-control" v-model="role">
                                        <option value="admin">Admin</option>
                                        <option value="user">User</option>
                                        <option value="guest">Guest</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Hak Akses</label><br />
                                    <div class="row permission">
                                        <div class="col-md-12">
                                            <input type="checkbox" id="group-sarpras" value="sarpras" v-model="working_group"> <label for="group-sarpras" class="me-3">Sarpras</label>
                                            <input type="checkbox" id="group-operasi" value="operasi" v-model="working_group"> <label for="group-operasi">Operasi SDA</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="">Name</label>
                                    <input type="text" name="name" class="form-control" v-model="name">
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="">Email</label>
                                    <input type="text" name="email" class="form-control" v-model="email">
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="">Username</label>
                                    <input type="text" name="username" class="form-control" v-model="username">
                                </div>
                            </div>
                        </div>
                        <hr>
                        <h4>Akses Telemeteri</h4>
                        <p><b>AWLR</b></p>
                        <div class="row">
                            <div class="col-3 col-xxl-2 mb-2" v-for="station in awlr" :key="station.id">
                                <input type="checkbox" :id="`st-${station.id}`" class="me-2" v-model="stations" :value="station.id">
                                <label :for="`st-${station.id}`">{{ station.name }}</label>
                            </div>
                        </div>
                        <p class="mt-3"><b>ARR</b></p>
                        <div class="row">
                            <div class="col-3 col-xxl-2 mb-2" v-for="station in arr" :key="station.id">
                                <input type="checkbox" :id="`st-${station.id}`" class="me-2" v-model="stations" :value="station.id">
                                <label :for="`st-${station.id}`">{{ station.name }}</label>
                            </div>
                        </div>
                        <p class="mt-3"><b>WQS</b></p>
                        <div class="row">
                            <div class="col-3 col-xxl-2 mb-2" v-for="station in wqs" :key="station.id">
                                <input type="checkbox" :id="`st-${station.id}`" class="me-2" v-model="stations" :value="station.id">
                                <label :for="`st-${station.id}`">{{ station.name }}</label>
                            </div>
                        </div>
                        <p class="mt-3"><b>AWS</b></p>
                        <div class="row">
                            <div class="col-3 col-xl-2 mb-2" v-for="station in aws" :key="station.id">
                                <input type="checkbox" :id="`st-${station.id}`" class="me-2" v-model="stations" :value="station.id">
                                <label :for="`st-${station.id}`">{{ station.name }}</label>
                            </div>
                        </div>
                        <hr>
                        <h4>Akses Modul</h4>
                        <div class="row mb-3">
                            <div class="col-2 col-xxl-1" v-for="menu in menus[0]" :key="menu.id">
                                <div class="d-flex flex-column align-items-center rounded module py-2" :class="modules.indexOf(menu.id) > -1 ? 'module-selected':''" @click="toggleModuleAccess(menu.id)">
                                    <img :src="require(`@/assets/${menu.logo}.png`)" alt="" style="width: 70px;">
                                    <div class="text-center">
                                        {{ menu.text.toUpperCase() }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-2 col-xxl-1" v-for="menu in menus[1]" :key="menu.id">
                                <div class="d-flex flex-column align-items-center rounded module py-2" :class="modules.indexOf(menu.id) > -1 ? 'module-selected':''" @click="toggleModuleAccess(menu.id)">
                                    <img :src="require(`@/assets/${menu.logo}.png`)" alt="" style="width: 70px;">
                                    <div class="text-center">
                                        {{ menu.text.toUpperCase() }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-2 col-xxl-1" v-for="menu in menus[2]" :key="menu.id">
                                <div class="d-flex flex-column align-items-center rounded module py-2" :class="modules.indexOf(menu.id) > -1 ? 'module-selected':''" @click="toggleModuleAccess(menu.id)">
                                    <img :src="require(`@/assets/${menu.logo}.png`)" alt="" style="width: 70px;">
                                    <div class="text-center">
                                        {{ menu.text.toUpperCase() }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-2 col-xxl-1" v-for="menu in menus[3]" :key="menu.id">
                                <div class="d-flex flex-column align-items-center rounded module py-2" :class="modules.indexOf(menu.id) > -1 ? 'module-selected':''" @click="toggleModuleAccess(menu.id)">
                                    <img :src="require(`@/assets/${menu.logo}.png`)" alt="" style="width: 70px;">
                                    <div class="text-center">
                                        {{ menu.text.toUpperCase() }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mt-3">
                            <button class="btn btn-primary me-1" type="button" v-on:click="updateUser"><i class="fa fa-save"></i> Save</button>
                            <router-link to="/users/index" class="btn btn-danger"><i class="fa fa-times"></i> Cancel</router-link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from '../../components/Navbar.vue'
import Breadcrumb from '../../components/Breadcrumb.vue'
// import OperationSidebar from '../../components/OperationSidebar.vue'
import Select2 from 'v-select2-component';
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    name: 'UserEdit',
    components: {
        Navbar,
        Breadcrumb,
        // OperationSidebar,
        Select2,
        Loading
    },
    methods: {
        changeOrg(id){
            for(var i = 0; i < this.organizations.length; i++){
                if(this.organizations[i].id == id){
                    this.org_name = this.organizations[i].text;

                    break;
                }
            }
        },
        parseJson(str){
            try{
                if(str.length > 0 && typeof str === 'string'){
                    var json = JSON.parse(str);
                    if(typeof json === 'object'){
                        return json;
                    }else{
                        return [];
                    }
                }

                return [];
            }catch(e){
                return [];
            }
        },
        updateUser(){
            let fd = new FormData();
            fd.append('role', this.role);
            fd.append('name', this.name);
            fd.append('email', this.email);
            fd.append('username', this.username);
            if(this.org_id) {
                fd.append('ref_org_id', this.org_id);
            }
            fd.append('ref_org_name', this.org_name);
            fd.append('working_group', JSON.stringify(this.working_group));
            fd.append('telemeteri_access', JSON.stringify(this.stations));
            fd.append('module_access', JSON.stringify(this.modules));
            fd.append('_method', 'PUT');

            this.is_loading = true;

            axios.post(this.$endpoints.user+'users/update/'+this.id+'?token='+this.token, fd)
            .then(() => {
                this.is_loading = false;
                this.$router.push({path:'/users/index'});
            }).catch(() => {
                this.is_loading = false;
                alert('there was an error while updating user. please contact developer');
            })
        },
        toggleModuleAccess(module){
            let i = this.modules.indexOf(module);
            if(i > -1){
                this.modules.splice(i, 1);
            }else{
                this.modules.push(module);
            }
        }
    },
    data(){
        return {
            is_fullpage: true,
            is_loading: false,
            token: null,
            organizations: [],
            role: '',
            name: '',
            email: '',
            username: '',
            org_id: '',
            org_name: '',
            parent_org_id: '',
            parent_org_name:'',
            working_group: [],
            awlr: [],
            arr: [],
            wqs: [],
            aws: [],
            stations: [],
            menus: [
                [
                    {
                        id: 'sarpras',
                        text: 'SARPRAS',
                        logo: 'logo-sarpras'
                    },
                    {
                        id: 'operasi',
                        text: 'OPERASI',
                        logo: 'logo-operasi'
                    },
                    {
                        id: 'monitoring',
                        text: 'MONITORING',
                        logo: 'logo-monitoring'
                    },
                    {
                        id: 'skema',
                        text: 'SKEMA DISTRIBUSI AIR',
                        logo: 'logo-skema'
                    },
                    {
                        id: 'spasial',
                        text: 'SPASIAL',
                        logo: 'logo-map'
                    },
                    {
                        id: 'telemeteri',
                        text: 'TELEMETERI',
                        logo: 'logo-telemeteri'
                    }
                ],[
                    {
                        id: 'cctv',
                        text: 'CCTV',
                        logo: 'logo-cctv'
                    },
                    {
                        id: 'plta',
                        text: 'PLTA',
                        logo: 'logo-sarpras'
                    },
                    {
                        id: 'bendungan',
                        text: 'BENDUNGAN',
                        logo: 'logo-bendungan'
                    },
                    {
                        id: 'cuaca',
                        text: 'CUACA BMKG',
                        logo: 'logo-bmkg'
                    },
                ], [
                    {
                        id: 'scada',
                        text: 'SCADA',
                        logo: 'logo-scada'
                    },
                    {
                        id: 'sistarum',
                        text: 'SISTARUM',
                        logo: 'logo-sistarum'
                    },
                    {
                        id: 'watermeter',
                        text: 'Water Meter',
                        logo: 'logo-water-meter'
                    }
                ], [
                    {
                        id: 'referensi',
                        text: 'REFERENSI',
                        logo: 'logo-referensi'
                    },
                    {
                        id: 'user',
                        text: 'USER',
                        logo: 'logo-users'
                    }
                ]
            ],
            modules: []
        };
    },
    mounted(){
        this.token = localStorage.getItem('token');
        this.is_loading = true;

        axios.get(this.$endpoints.user+'users/'+this.$route.params.id+'?token='+this.token)
        .then(response => {
            this.is_loading = false;
            if(response.data['name']){
                this.id = response.data.id;
                this.name = response.data.name;
                this.email = response.data.email;
                this.username = response.data.username;
                this.role = response.data.role;
                this.org_id = response.data.ref_org_id;
                this.working_group = this.parseJson(response.data.working_group);
                this.stations = this.parseJson(response.data.telemeteri_access);
                this.modules = this.parseJson(response.data.module_access);
            }
        });
        
        // get organizations
        axios.get(this.$endpoints.reference+'organizations?all=1')
        .then((response) => {
            let data = [];
            for(var i = 0; i < response.data[0].length; i++){
                var text = response.data[0][i].name;
                switch(response.data[0][i].indent){
                    case 1:
                        text = "- "+text;
                        break;

                    case 2:
                        text = "-- "+text;
                        break;

                    case 3:
                        text = "--- "+text;
                        break;

                    case 4:
                        text = "---- "+text;
                        break;
                }
                data.push({
                    text,
                    id: response.data[0][i].id
                })
            }
            this.organizations = data;
            this.is_loading = false;
        })
        .catch(() => {
            this.is_loading = false;
            alert('there was an error. please contact developer.');
        })

        axios.get(`${this.$endpoints.telemeteri}stations`)
        .then(response => {
            this.awlr = response.data.awlr;
            this.arr = response.data.arr;
            this.wqs = response.data.wqs;
            this.aws = response.data.aws;
        });
    }
}
</script>

<style>

</style>