<template>
    <div>
        <loading :active.sync="is_loading" :can-cancel="true" :is-full-page="true">
        </loading>
        <Navbar />
        <SidebarWm />
        <div id="main" class="main">
            <Breadcrumb :links="links" />
            <section class="section dashboard">
                <div class="card">
                    <div class="card-body">
                        <div class="form-group mb-3">
                            <label for="">Nama perusahaan</label>
                            <input type="text" class="form-control" v-model="company.name" readonly>
                        </div>
                        <div class="form-group mb-3">
                            <label for="">Wilayah</label>
                            <input type="text" class="form-control" v-model="company.wilayah" readonly>
                        </div>
                        <div class="row">
                            <div class="col-lg-3">
                                <div class="form-group mb-3">
                                    <label for="">Threshold per bulan</label>
                                    <div class="input-group">
                                        <input type="text" class="form-control" v-model="company.threshold">
                                        <div class="input-group-append">
                                            <span class="input-group-text" id="basic-addon2">m<sup>3</sup></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <button class="btn btn-primary me-2" @click="save"><i class="fa fa-save"></i> Save</button>
                            <router-link :to="{name:'WaterMeterDashboard'}" class="btn btn-danger">
                                <i class="fa fa-times"></i> Cancel
                            </router-link>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Breadcrumb from '../../components/Breadcrumb.vue'
import Navbar from '../../components/Navbar.vue'
import SidebarWm from '../../components/SidebarWm.vue'
import axios from 'axios'

export default {
    components: {
        Navbar,
        Loading, 
        Breadcrumb,
        SidebarWm
    },
    data(){
        return {
            is_loading: false,
            links: [
                {url: '/dashboard', text:'Home'}, 
                {url: '/watermeter', text: 'Water Meter'}, 
                {url: '#', text: 'Edit'}
            ],
            company: {
            }
        }
    },
    mounted(){
        this.is_loading = true;
        const { id } = this.$route.params;

        axios.get(`${this.$endpoints.watermeter}/companies/edit/${id}`)
        .then(response => {
            this.company = response.data;
        })
        .finally(() => {
            this.is_loading = false;
        })
    },
    methods: {
        save(){
            this.is_loading = true;
            const { id } = this.$route.params;

            axios.post(`${this.$endpoints.watermeter}/companies/edit/${id}`, {
                threshold: this.company.threshold
            })
            .then(response => {
                this.$swal("Berhasil", response.data.message, "success");
                this.$router.push({
                    name: 'WaterMeterDashboard'
                })
            })
            .finally(() => {
                this.is_loading = false;
            })
        }
    }
}
</script>