import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '../views/login/Login.vue'
import Dashboard from '../views/dashboard/Dashboard.vue'

// Operation
import OperationDashboard from '../views/operation/Dashboard.vue'
import OperationInputData from '@/views/operation/Input.vue'
import OperationManageData from '@/views/operation/ManageData.vue'
import OperationSeriDataTable from '@/views/operation/SeriDataTable.vue'
import OperationSeriDataGrafik from '@/views/operation/SeriDataGrafik.vue'
import ImportSeriData from '@/views/operation/Import.vue'

// Reference Organization
import OrganizationIndex from '../views/reference/organisasi/Index.vue'
import OrganizationCreate from '../views/reference/organisasi/Create.vue'
import OrganizationEdit from '../views/reference/organisasi/Edit.vue'

// Reference Location
import LocationIndex from '../views/reference/lokasi/Index.vue'
import LocationCreate from '../views/reference/lokasi/Create.vue'
import LocationEdit from '../views/reference/lokasi/Edit.vue'

// Reference Petugas
import PetugasIndex from '../views/reference/petugas/Index.vue'
import PetugasCreate from '../views/reference/petugas/Create.vue'
import PetugasEdit from '../views/reference/petugas/Edit.vue'

// Reference Operator
import OperatorIndex from '../views/reference/operator/Index.vue'
import OperatorCreate from '../views/reference/operator/Create.vue'
import OperatorEdit from '../views/reference/operator/Edit.vue'

// Reference Jenis Lokasi
import JenisLokasiIndex from '../views/reference/jenis_lokasi/Index.vue' 
import JenisLokasiCreate from '../views/reference/jenis_lokasi/Create.vue' 
import JenisLokasiEdit from '../views/reference/jenis_lokasi/Edit.vue' 

// Reference Satuan
import SatuanIndex from '../views/reference/satuan/Index.vue' 
import SatuanCreate from '../views/reference/satuan/Create.vue' 
import SatuanEdit from '../views/reference/satuan/Edit.vue'

// Reference Besaran
import BesaranIndex from '../views/reference/besaran/Index.vue' 
import BesaranCreate from '../views/reference/besaran/Create.vue' 
import BesaranEdit from '../views/reference/besaran/Edit.vue' 

// Reference Periodik
import PeriodikIndex from '../views/reference/periodik/Index.vue' 
import PeriodikCreate from '../views/reference/periodik/Create.vue' 
import PeriodikEdit from '../views/reference/periodik/Edit.vue' 

import SarprasDashboardTemp from '../views/sarpras/TempDashboard.vue'
import SarprasDashboard from '../views/sarpras/Dashboard.vue'

// Sarpras Jenis Sarpras
import JenisIndex from '../views/sarpras/jenis_sarpras/Index.vue'
import JenisCreate from '../views/sarpras/jenis_sarpras/Create.vue'
import JenisEdit from '../views/sarpras/jenis_sarpras/Edit.vue'

// Sarpras Jenis Lokasi
import SarprasLokasiIndex from '../views/sarpras/jenis_lokasi/Index.vue'
import SarprasLokasiCreate from '../views/sarpras/jenis_lokasi/Create.vue'
import SarprasLokasiEdit from '../views/sarpras/jenis_lokasi/Edit.vue'

// Sarpras Subjenis
import SarprasSubjenisIndex from '../views/sarpras/subjenis/Index.vue'
import SarprasSubjenisCreate from '../views/sarpras/subjenis/Create.vue'
import SarprasSubjenisEdit from '../views/sarpras/subjenis/Edit.vue'

// Sarpras Subjenis
import SarprasKomponenIndex from '../views/sarpras/komponen/Index.vue'
import SarprasKomponenCreate from '../views/sarpras/komponen/Create.vue'
import SarprasKomponenEdit from '../views/sarpras/komponen/Edit.vue'

// Sarpras Kategori
import SarprasKategoriIndex from '../views/sarpras/kategori/Index.vue'
import SarprasKategoriCreate from '../views/sarpras/kategori/Create.vue'
import SarprasKategoriEdit from '../views/sarpras/kategori/Edit.vue'

// Sarpras Riwayat
import SarprasRiwayatIndex from '../views/sarpras/riwayat_sarpras/Index.vue'
import SarprasRiwayatCreate from '../views/sarpras/riwayat_sarpras/Create.vue'
import SarprasRiwayatEdit from '../views/sarpras/riwayat_sarpras/Edit.vue'
import SarprasDetail from '../views/sarpras/riwayat_sarpras/Detail.vue'

// inspeksi
import InspectionAdd from '../views/sarpras/inspections/Add.vue'
import InspectionEdit from '../views/sarpras/inspections/Edit.vue'

// User
import UserIndex from '../views/users/Index.vue'
import UserEdit from '../views/users/Edit.vue'
import UserCreate from '../views/users/Create.vue'
import UserBatchEdit from '../views/users/BatchEdit.vue'

// Skema
import Skema from '../views/skema/Index.vue'
import Subgrafik from '@/views/skema/Subgrafik.vue'

// Spatial
import Spatial from '../views/spatial/Index.vue'

// Telemeteri
import Telemeteri from '../views/telemeteri/Index.vue'
import TelemeteriEdit from '../views/telemeteri/Edit.vue'
import GrafikTelemeteri from '../views/telemeteri/Grafik.vue'

// History Telemeteri
import HistoryTelemeteri from '../views/telemeteri/History.vue'

// Bantuan
import Bantuan from '../views/home/Bantuan.vue'

import Cctv from '../views/operation/Cctv.vue'

// PLTA
import PLTA from '../views/plta/Index.vue'

// Bendungan
import Bendungan from '../views/bendungan/Index.vue'

// BMKG
import BMKG from '../views/bmkg/Index.vue'

import Unauthorized from '../views/Unauthorized';

// PAB
import PAB from '../views/pab/Index.vue'

import SimulasiTma from '../views/operation/SimulasiTma.vue'
import ProduksiListrik from '../views/operation/ProduksiListrik.vue'

import Geomoss from '../views/bendungan/Geomoss.vue';

import DashboardScada from '../views/scada/Dashboard.vue'
import LocationScada from '../views/scada/LocationTable.vue'
import LocationScadaGraphic from '../views/scada/LocationGraphic.vue'

import OperationCompareDataGrafik from '../views/operation/CompareGrafik.vue'
import OperationCompareDataTable from '../views/operation/CompareTable.vue'
import TelemtriDiagnostic from '../views/telemeteri/Diagnostic.vue'

import SistarumMap from '../views/sistarum/Map.vue';
import SistarumDetail from '../views/sistarum/Detail.vue';

import WaterMeterDashboard from '../views/watermeter/Dashboard.vue';
import WaterMeterDetail from '../views/watermeter/Detail.vue';
import WaterMeterEdit from '../views/watermeter/Edit.vue';


Vue.use(VueRouter)
Vue.component('pagination', require('laravel-vue-pagination'));

const routes = [

    // Home
    { path: '/', name: 'Home',  component: Login },
    { path: '/login', name: 'Login', component: Login },
    { path: '/dashboard', name: 'Dashboard', meta: {requiresAuth: true}, component: Dashboard },
    
    { path: '/operation/dashboard', name: 'OperationDashboard', meta: {requiresAuth: true, checkAccessRole: true, module: 'operasi'}, component: OperationDashboard },
    { path: '/operation/input_data', name: 'OperationInputData', meta: {requiresAuth: true, checkAccessRole: true, preventFromGuest: true, module: 'operasi'}, component: OperationInputData },
    { path: '/operation/manage_data', name: 'OperationManageData', meta: {requiresAuth: true, checkAccessRole: true, module: 'operasi'}, component: OperationManageData },
    { path: '/operation/seri_data/tabel', name: 'OperationSeriDataTable', meta: {requiresAuth: true, checkAccessRole: true, module: 'operasi'}, component: OperationSeriDataTable },
    { path: '/operation/seri_data/grafik', name: 'OperationSeriDataGrafik', meta: {requiresAuth: true, checkAccessRole: true, module: 'operasi'}, component: OperationSeriDataGrafik },
    { path: '/operation/import', name: 'ImportSeriData', meta: {requiresAuth: true, checkAccessRole: true, preventFromGuest: true, module: 'operasi'}, component: ImportSeriData },
    
    // Referensi Organinasi
    { path: '/referensi/organisasi/index', name: 'OrganizationIndex', meta: {requiresAuth: true, checkAccessRole: true, module: 'referensi'}, component: OrganizationIndex },
    { path: '/referensi/organisasi/create', name: 'OrganizationCreate', meta: {requiresAuth: true, checkAccessRole: true, preventFromGuest:true, module: 'referensi'}, component: OrganizationCreate },
    { path: '/referensi/organisasi/edit/:id', name: 'OrganizationEdit', meta: {requiresAuth: true, checkAccessRole: true, preventFromGuest:true, module: 'referensi'}, component: OrganizationEdit },

    // Referensi Lokasi
    { path: '/referensi/lokasi/index', name: 'LocationIndex', meta: {requiresAuth: true, checkAccessRole: true, module: 'referensi'}, component: LocationIndex },
    { path: '/referensi/lokasi/create', name: 'LocationCreate', meta: {requiresAuth: true, checkAccessRole: true, preventFromGuest: true, module: 'referensi'}, component: LocationCreate },
    { path: '/referensi/lokasi/edit/:id', name: 'LocationEdit', meta: {requiresAuth: true, checkAccessRole: true, preventFromGuest: true, module: 'referensi'}, component: LocationEdit },
    
    // Referensi Petugas
    { path: '/referensi/petugas/index', name: 'PetugasIndex', meta: {requiresAuth: true, checkAccessRole: true, module: 'referensi'}, component: PetugasIndex },
    { path: '/referensi/petugas/create', name: 'PetugasCreate', meta: {requiresAuth: true, checkAccessRole: true, preventFromGuest: true, module: 'referensi'}, component: PetugasCreate },
    { path: '/referensi/petugas/edit/:id', name: 'PetugasEdit', meta: {requiresAuth: true, checkAccessRole: true, preventFromGuest: true, module: 'referensi'}, component: PetugasEdit },
    
    // Referensi Operator
    { path: '/referensi/operator/index', name: 'OperatorIndex', meta: {requiresAuth: true, checkAccessRole: true, module: 'referensi'}, component: OperatorIndex },
    { path: '/referensi/operator/create', name: 'OperatorCreate', meta: {requiresAuth: true, checkAccessRole: true, preventFromGuest: true, module: 'referensi'}, component: OperatorCreate },
    { path: '/referensi/operator/edit/:id', name: 'OperatorEdit', meta: {requiresAuth: true, checkAccessRole: true, preventFromGuest: true, module: 'referensi'}, component: OperatorEdit },
    
    // Jenis Lokasi
    { path: '/referensi/jenis/index', name: 'JenisLokasiIndex', meta: {requiresAuth: true, checkAccessRole: true, module: 'referensi'}, component: JenisLokasiIndex },
    { path: '/referensi/jenis/create', name: 'JenisLokasiCreate', meta: {requiresAuth: true, checkAccessRole: true, preventFromGuest: true, module: 'referensi'}, component: JenisLokasiCreate },
    { path: '/referensi/jenis/edit/:id', name: 'JenisLokasiEdit', meta: {requiresAuth: true, checkAccessRole: true, preventFromGuest: true, module: 'referensi'}, component: JenisLokasiEdit },

    // Satuan
    { path: '/referensi/satuan/index', name: 'SatuanIndex', meta: {requiresAuth: true, checkAccessRole: true, module: 'referensi'}, component: SatuanIndex },
    { path: '/referensi/satuan/create', name: 'SatuanCreate', meta: {requiresAuth: true, checkAccessRole: true, preventFromGuest: true, module: 'referensi'}, component: SatuanCreate },
    { path: '/referensi/satuan/edit/:id', name: 'SatuanEdit', meta: {requiresAuth: true, checkAccessRole: true, preventFromGuest: true, module: 'referensi'}, component: SatuanEdit },

    // Besaran
    { path: '/referensi/besaran/index', name: 'BesaranIndex', meta: {requiresAuth: true, checkAccessRole: true, module: 'referensi'}, component: BesaranIndex },
    { path: '/referensi/besaran/create', name: 'BesaranCreate', meta: {requiresAuth: true, checkAccessRole: true, preventFromGuest: true, module: 'referensi'}, component: BesaranCreate },
    { path: '/referensi/besaran/edit/:id', name: 'BesaranEdit', meta: {requiresAuth: true, checkAccessRole: true, preventFromGuest: true, module: 'referensi'}, component: BesaranEdit },

    // Periodik
    { path: '/referensi/periodik/index', name: 'PeriodikIndex', meta: {requiresAuth: true}, component: PeriodikIndex },
    { path: '/referensi/periodik/create', name: 'PeriodikCreate', meta: {requiresAuth: true}, preventFromGuest: true, component: PeriodikCreate },
    { path: '/referensi/periodik/edit/:id', name: 'PeriodikEdit', meta: {requiresAuth: true}, preventFromGuest: true, component: PeriodikEdit },

    // Sarpras Dashboard
    { path: '/sarpras/dashboard', name: 'SarprasDashboardApi', meta: {requiresAuth: true, checkAccessRole: true, module: 'sarpras'}, component: SarprasDashboardTemp },
    { path: '/sarpras/dashboard-field', name: 'SarprasDashboard', meta: {requiresAuth: true, checkAccessRole: true, module: 'sarpras'}, component: SarprasDashboard },

    // Sarpras Jenis
    { path: '/sarpras/jenis/index', name: 'JenisIndex', meta: {requiresAuth: true, checkAccessRole: true, module: 'sarpras'}, component: JenisIndex },
    { path: '/sarpras/jenis/create', name: 'JenisCreate', meta: {requiresAuth: true, checkAccessRole: true, preventFromGuest: true, module: 'sarpras'}, component: JenisCreate },
    { path: '/sarpras/jenis/edit/:id', name: 'JenisEdit', meta: {requiresAuth: true, checkAccessRole: true, preventFromGuest: true, module: 'sarpras'}, component: JenisEdit },
    
    // Sarpras Jenis Lokasi
    { path: '/sarpras/lokasi/index', name: 'SarprasLokasiIndex', meta: {requiresAuth: true, checkAccessRole: true, module: 'sarpras'}, component: SarprasLokasiIndex },
    { path: '/sarpras/lokasi/create', name: 'SarprasLokasiCreate', meta: {requiresAuth: true, checkAccessRole: true, preventFromGuest: true, module: 'sarpras'}, component: SarprasLokasiCreate },
    { path: '/sarpras/lokasi/edit/:id', name: 'SarprasLokasiEdit', meta: {requiresAuth: true, checkAccessRole: true, preventFromGuest: true, module: 'sarpras'}, component: SarprasLokasiEdit },
    
    // Sarpras SubJenis Lokasi
    { path: '/sarpras/subjenis/index', name: 'SarprasSubjenisIndex', meta: {requiresAuth: true, checkAccessRole: true, module: 'sarpras'}, component: SarprasSubjenisIndex },
    { path: '/sarpras/subjenis/create', name: 'SarprasSubjenisCreate', meta: {requiresAuth: true, checkAccessRole: true, preventFromGuest: true, module: 'sarpras'}, component: SarprasSubjenisCreate },
    { path: '/sarpras/subjenis/edit/:id', name: 'SarprasSubjenisEdit', meta: {requiresAuth: true, checkAccessRole: true, preventFromGuest: true, module: 'sarpras'}, component: SarprasSubjenisEdit },
    
    // Sarpras Komponen 
    { path: '/sarpras/komponen/index', name: 'SarprasKomponenIndex', meta: {requiresAuth: true, checkAccessRole: true, module: 'sarpras'}, component: SarprasKomponenIndex },
    { path: '/sarpras/komponen/create', name: 'SarprasKomponenCreate', meta: {requiresAuth: true, checkAccessRole: true, preventFromGuest: true, module: 'sarpras'}, component: SarprasKomponenCreate },
    { path: '/sarpras/komponen/edit/:id', name: 'SarprasKomponenEdit', meta: {requiresAuth: true, checkAccessRole: true, preventFromGuest: true, module: 'sarpras'}, component: SarprasKomponenEdit },

    // Sarpras Kategori
    { path: '/sarpras/kategori/index', name: 'SarprasKategoriIndex', meta: {requiresAuth: true, checkAccessRole: true, module: 'sarpras'}, component: SarprasKategoriIndex },
    { path: '/sarpras/kategori/create', name: 'SarprasKategoriCreate', meta: {requiresAuth: true, checkAccessRole: true, preventFromGuest: true, module: 'sarpras'}, component: SarprasKategoriCreate },
    { path: '/sarpras/kategori/edit/:id', name: 'SarprasKategoriEdit', meta: {requiresAuth: true, checkAccessRole: true, preventFromGuest: true, module: 'sarpras'}, component: SarprasKategoriEdit },
    
    // Sarpras Riwayat
    { path: '/sarpras/infrastructure/index', name: 'SarprasRiwayatIndex', meta: {requiresAuth: true, checkAccessRole: true, module: 'sarpras'}, component: SarprasRiwayatIndex },
    { path: '/sarpras/infrastructure/create', name: 'SarprasRiwayatCreate', meta: {requiresAuth: true, checkAccessRole: true, preventFromGuest: true, module: 'sarpras'}, component: SarprasRiwayatCreate },
    { path: '/sarpras/infrastructure/edit/:id', name: 'SarprasRiwayatEdit', meta: {requiresAuth: true, checkAccessRole: true, preventFromGuest: true, module: 'sarpras'}, component: SarprasRiwayatEdit },
    { path: '/sarpras/infrastructure/detail/:id', name: 'SarprasDetail', meta: {requiresAuth: true, checkAccessRole: true, module: 'sarpras'}, component: SarprasDetail },

    // inspeksi
    { path: '/sarpras/inspections/add/:id', name: 'InspectionAdd', meta: {requiresAuth: true, checkAccessRole: true, preventFromGuest: true, module: 'sarpras'}, component: InspectionAdd },
    { path: '/sarpras/inspections/edit/:id/:inspection_id', name: 'InspectionEdit', meta: {requiresAuth: true, checkAccessRole: true, preventFromGuest: true, module: 'sarpras'}, component: InspectionEdit },
    
    // User
    { path: '/users/index', name: 'UserIndex', meta: {requiresAuth: true, checkAccessRole: true, module: 'user'}, component: UserIndex },
    { path: '/users/create', name: 'UserCreate', meta: {requiresAuth: true, checkAccessRole: true, preventFromGuest: true, module: 'user'}, component: UserCreate },
    { path: '/users/edit/:id', name: 'UserEdit', meta: {requiresAuth: true, checkAccessRole: true, preventFromGuest: true, module: 'user'}, component: UserEdit },
    { path: '/users/batch_edit', name: 'UserBatchEdit', meta: {requiresAuth: true, checkAccessRole: true, preventFromGuest: true, module: 'user'}, component: UserBatchEdit },
    
    // Skema
    { path: '/skema', name: 'Skema', meta: {requiresAuth: true, checkAccessRole: true, module: 'skema'}, component: Skema },
    { path: '/skema/subgrafik', name: 'Subgrafik', meta: {requiresAuth: true, checkAccessRole: true, module: 'skema'}, component: Subgrafik },
    

    // Spatial
    { path: '/spatial', name: 'Spatial', meta: {requiresAuth: true, checkAccessRole: true, module: 'spasial'}, component: Spatial },
    
    // Telemeteri
    { path: '/telemeteri', name: 'Telemeteri', meta: {requiresAuth: true, checkAccessRole: true, module: 'telemeteri'}, component: Telemeteri },
    { path: '/telemeteri/edit/:id', name: 'TelemeteriEdit', meta: {requiresAuth: true, checkAccessRole: true, preventFromGuest: true, module: 'telemeteri'}, component: TelemeteriEdit },
    { path: '/grafik/telemeteri', name: 'GrafikTelemeteri', meta: {requiresAuth: true, checkAccessRole: true, module: 'telemeteri'}, component: GrafikTelemeteri },
    
    // History Telemeteri
    { path: '/history/telemeteri', name: 'HistoryTelemeteri', meta: {requiresAuth: true}, component: HistoryTelemeteri },

    // Diagnostic Telemetri
    { path: '/telemeteri/diagnostic', name: 'TelemtriDiagnostic', meta: {requiresAuth: true}, component: TelemtriDiagnostic },
    
    // Bantuan
    { path: '/bantuan', name: 'Bantuan', component: Bantuan },
    
    {path: '/cctv', name: 'CCTV', component: Cctv, meta: {requiresAuth: true, checkAccessRole: true, module: 'cctv'}},
    
    // PLTA
    { path: '/plta', name: 'PLTA', component: PLTA, meta: {requiresAuth: true, checkAccessRole: true, module: 'plta'}},
    
    // Bendungan
    { path: '/bendungan', name: 'Bendungan', component: Bendungan, meta: {requiresAuth: true, checkAccessRole: true, module: 'bendungan'}},
    { path: '/geomoss/:source', name: 'geomoss_index', component: Geomoss, meta: {requiresAuth: true, checkAccessRole: true, module: 'bendungan'}},
    
    // BMKG
    { path: '/bmkg', name: 'BMKG', component: BMKG, meta: {requiresAuth: true, checkAccessRole: true, module: 'cuaca'}},
    { path: '/pab', name: 'PAB', component: PAB, meta: {requiresAuth: true, checkAccessRole: true, module: 'pab'}},

    // simulasi
    { path: '/operation/simulasi-tma', name: 'SimulasiTma', component: SimulasiTma, meta: {requiresAuth: true, checkAccessRole: true, module: 'operasi'}},
    { path: '/operation/produksi-listrik', name: 'ProduksiListrik', component: ProduksiListrik, meta: {requiresAuth: true, checkAccessRole: true, module: 'operasi'}},

    { path: '/scada/dashboard', name: 'DashboardScada', component: DashboardScada, meta: {requiresAuth: true, checkAccessRole: true, module: 'operasi'}},
    { path: '/scada/location/table', name: 'LocationScadaTable', component: LocationScada, meta: {requiresAuth: true, checkAccessRole: true, module: 'operasi'}},
    { path: '/scada/location/graphic', name: 'LocationScadaGraphic', component: LocationScadaGraphic, meta: {requiresAuth: true, checkAccessRole: true, module: 'operasi'}},

    { path: '/operation/compare/graphic', name: 'OperationCompareDataGrafik', component: OperationCompareDataGrafik, meta: {requiresAuth: true, checkAccessRole: true, module: 'operasi'}},
    { path: '/operation/compare/table', name: 'OperationCompareDataTable', component: OperationCompareDataTable, meta: {requiresAuth: true, checkAccessRole: true, module: 'operasi'}},

    { path: '/sistarum', name: 'SistarumMap', component: SistarumMap, meta: {requiresAuth: true, checkAccessRole: true, module: 'sistarum'}},
    { path: '/sistarum/:id', name: 'SistarumDetail', component: SistarumDetail, meta: {requiresAuth: true, checkAccessRole: true, module: 'sistarum'}},

    { path: '/watermeter', name: 'WaterMeterDashboard', component: WaterMeterDashboard, meta: {requiresAuth: true, checkAccessRole: true, module: 'watermeter'}},
    { path: '/watermeter/:id', name: 'WaterMeterDetail', component: WaterMeterDetail, meta: {requiresAuth: true, checkAccessRole: true, module: 'watermeter'}},
    { path: '/watermeter/:id/edit', name: 'WaterMeterEdit', component: WaterMeterEdit, meta: {requiresAuth: true, checkAccessRole: true, module: 'watermeter'}},

    {path: '/unauthorized', name: 'unauthorized', component: Unauthorized}


]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    linkActiveClass: 'active',
    inactiveClass: 'collapsed',
    linkExactActiveClass: 'exact-active',
    routes
})

router.beforeEach((to, from, next) => {
    let token = localStorage.getItem('token') != null;
    let role = localStorage.getItem('role');
    
    if (to.name === 'login' && token){
        next({ name: 'dashboard' })
    }else if(to.matched.some(record => record.meta.requiresAuth) || to.matched.some(record => record.meta.checkAccessRole)) {
        if (!token) {
            next({
                path: '/login',
                query: { redirect: to.fullPath }
            })
        } else {
            if(to.matched.some(record => record.meta.checkAccessRole)){
                let access = JSON.parse(localStorage.getItem('module_access'));
                
                if(access.indexOf(to.meta.module) == -1 && role != 'admin'){
                    next({
                        path: '/unauthorized',
                    });
                }else{
                    if(to.matched.some(record => record.meta.preventFromGuest)){
                        if(role == 'guest'){
                            next({
                                path: '/unauthorized',
                            });
                        }else{
                            next();
                        }
                    }else{
                        next()
                    }

                    // next();
                }
            }else{
                next();
            }
        }
    }else{
        next();
    }
})

export default router
