<template>
    <div>
        <loading :active.sync="is_loading" :can-cancel="true" :is-full-page="true">
        </loading>
        <Navbar />
        <SidebarSistarum />
        <div id="main" class="main">
            <Breadcrumb :links="links" />
            <section class="section dashboard">
                <div class="row">
                    <div :class="isLocationSelected ? 'col-lg-9':'col-lg-12'">
                        <GmapMap
                            :center="{lat: -6.2500114, lng: 106.9895103}"
                            :zoom="10"
                            map-type-id="roadmap"
                            style="width: 100%; height: 600px"
                            ref="peta">
                                <GmapMarker
                                    v-for="marker in locations"
                                    :key="marker.hardware_code"
                                    :icon="{url:defineIcon()}"
                                    :clickable="true"
                                    :position="{lat: parseFloat(marker.lat), lng:parseFloat(marker.lng)}"
                                    :title="marker.name"
                                    @click="showLocation(marker.hardware_code)">
                                </GmapMarker>
                        </GmapMap>
                    </div>
                    <div class="col-lg-3" v-if="isLocationSelected">
                        <div class="card">
                            <div class="card-body">
                                <h4>{{ selected_location.name }}</h4>
                                <p>Koordinat: LS {{ selected_location.lat }}, BT {{ selected_location.lng }}</p>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>TMA</th>
                                            <th>Debit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{ selected_location.latest_tma }}</td>
                                            <td>{{ parseFloat(selected_location.latest_debit).toFixed(2) }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <router-link :to="{name:'SistarumDetail', params: {id: selected_location.hardware_code}}" class="btn btn-primary">
                                    Lihat detail
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Breadcrumb from '../../components/Breadcrumb.vue'
import Navbar from '../../components/Navbar.vue'
import SidebarSistarum from '../../components/SidebarSistarum.vue'
import axios from 'axios'

export default {
    components: {
        Navbar,
        Loading, 
        Breadcrumb,
        SidebarSistarum
    },
    data(){
        return {
            is_loading: false,
            links: [
                {url: '/dashboard', text: 'Home'},
                {url: '#', text: 'Sistarum'}
            ],
            locations: [],
            selected_location: {}
        }
    },
    mounted(){
        axios.get(`${this.$endpoints.sistarum}/locations`)
        .then(response => {
            this.locations = response.data;
        })
    },
    methods: {
        defineIcon() {
            return require('../../assets/marker/icon-awlr-biru.png');
        },
        showLocation(hc){
            const location = this.locations.filter(loc => loc.hardware_code == hc);
            if(location.length > 0){
                this.selected_location = location[0];
            }
        }
    },
    computed: {
        isLocationSelected(){
            return Object.keys(this.selected_location).length > 0;
        }
    }
}
</script>