<template>
    <ul class="pagination">
        <li class="page-item" :class="page == current_page ? 'active':''" v-for="(page, i) in pages" :key="i">
            <a class="page-link" href="#" @click.prevent="$emit('change-page', page)">{{  page }}</a>
        </li>
    </ul>
</template>

<script>

export default {
    props: {
        totalPages: {
            type: Number,
            default: null
        },
        currentPage:{
            type: Number,
            default: 1
        }
    },
    data(){
        return {
            total_page: 1,
            current_page: 1,
            pages: []
        }
    },
    methods: {
        pagination(c, m) {
            var current = c,
                last = m,
                delta = 2,
                left = current - delta,
                right = current + delta + 1,
                range = [],
                rangeWithDots = [],
                l;

            for (let i = 1; i <= last; i++) {
                if (i == 1 || i == last || i >= left && i < right) {
                    range.push(i);
                }
            }

            for (let i of range) {
                if (l) {
                    if (i - l === 2) {
                        rangeWithDots.push(l + 1);
                    } else if (i - l !== 1) {
                        rangeWithDots.push('...');
                    }
                }
                rangeWithDots.push(i);
                l = i;
            }

            // return rangeWithDots;
            this.pages = rangeWithDots;
        }
    },
    watch:{
        totalPages(val){
            this.total_page = val;
            this.pagination(this.current_page, this.total_page);
        },
        currentPage(val){
            this.current_page = val;
            this.pagination(this.current_page, this.total_page);
        }
    }
};
</script>