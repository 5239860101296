<template>
    <div>
        <loading :active.sync="is_loading" :can-cancel="true" :is-full-page="true">
        </loading>
        <Navbar />
        <SidebarWm />
        <div id="main" class="main">
            <Breadcrumb :links="links" />
            <section class="section dashboard">
                <div class="row">
                    <div class="col-md-3">
                        <div class="card">
                            <div class="card-body">
                                Total Perusahaan
                                <span class="d-block" style="font-size: 48px"><b>{{ total.company }}</b></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="card">
                            <div class="card-body">
                                Total Alat
                                <span class="d-block" style="font-size: 48px"><b>{{ total.watermeter }}</b></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-body">
                                <button class="btn btn-success mb-3" @click="downloadCsv"><i class="fa fa-download"></i> Download CSV</button>
                                <div class="form-group mb-3">
                                    <div class="input-group">
                                        <input type="text" class="form-control" v-model="query" @keyup="searchOnEnter">
                                        <button class="btn btn-danger" v-if="query.length > 3" @click="() => {
                                            query = '';
                                            getData()
                                        }"><i class="fa fa-times"></i> clear search</button>
                                    </div>
                                </div>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Nama perusahaan</th>
                                            <th>Wilayah</th>
                                            <th>Threshold</th>
                                            <th>Total pemakaian bulan berjalan</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="row in companies.data" :key="row.company_id">
                                            <td>{{ row.company_name }}</td>
                                            <td>{{ row.wilayah }}</td>
                                            <td>{{ row.threshold == null ? '-':row.threshold }} m3</td>
                                            <td>{{ row.total_wm_consumption }} m3</td>
                                            <td width="10%">
                                                <router-link :to="{name: 'WaterMeterDetail', params: {id: row.company_id}}" class="btn btn-sm btn-primary me-2">
                                                    <i class="fa fa-eye"></i>
                                                </router-link>
                                                <router-link :to="{name: 'WaterMeterEdit', params: {id: row.company_id}}" class="btn btn-sm btn-success me-2">
                                                    <i class="fa fa-pen"></i>
                                                </router-link>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <Paginate :totalPages="companies.meta.pages" :currentPage="parseInt(companies.meta.current_page)" @change-page="getCompaniesData" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Breadcrumb from '../../components/Breadcrumb.vue'
import Navbar from '../../components/Navbar.vue'
import SidebarWm from '../../components/SidebarWm.vue'
import Paginate from '../../components/Paginate.vue'
import axios from 'axios'

export default {
    components: {
        Navbar,
        Loading, 
        Breadcrumb,
        SidebarWm,
        Paginate
    },
    data(){
        return {
            is_loading: false,
            links: [
                {url: '/dashboard', text:'Home'}, 
                {url: '#', text: 'Water Meter'}
            ],
            companies: {
                data: [],
                meta: {}
            },
            total: {},
            query: ''
        }
    },
    mounted(){
        this.getData();
    },
    methods: {
        async getData(){
            this.is_loading = true;

            try {
                const responseCompanies = await axios.get(`${this.$endpoints.watermeter}/companies`, {
                    params: {
                        query: this.query
                    }
                })
                const total = await axios.get(`${this.$endpoints.watermeter}/companies/total`)
                this.companies = responseCompanies.data;
                this.total = total.data;
                
            } catch (e) {
                console.log(e);
            }

            this.is_loading = false;
        },
        arrayToCSV(array) {
            const csvRows = [];
            
            // Get headers from the first object keys
            const headers = Object.keys(array[0]);
            csvRows.push(headers.join(',')); // join headers with commas

            // Loop through the array and create rows
            for (const row of array) {
                const values = headers.map(header => {
                    let escaped = ('' + row[header]).replace(/"/g, '\\"'); // escape double quotes
                    // if(header == 'tanggal'){
                    //     escaped = moment(escaped).format("YYYY-MM-DD");
                    // }else{
                    //     escaped += " m3"
                    // }
                    return `"${escaped}"`; // wrap values in double quotes
                });
                csvRows.push(values.join(',')); // join values with commas
            }

            return csvRows.join('\n'); // join rows with new line
        },
        downloadCsv(){
            this.is_loading = true;
            axios.get(`${this.$endpoints.watermeter}/companies`, {
                params: {
                    to_csv: 1
                }
            })
            .then(response => {
                const csvString = this.arrayToCSV(response.data.data);
                const blob = new Blob([csvString], { type: 'text/csv' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                const filename = `watermeter.csv`;

                a.setAttribute('hidden', '');
                a.setAttribute('href', url);
                a.setAttribute('download', filename);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                this.is_loading = false;
            })
            
        },
        getCompaniesData(page){
            this.is_loading = true;
            axios.get(`${this.$endpoints.watermeter}/companies`, {
                params:{
                    page,
                    query: this.query
                }
            })
            .then(response => {
                this.companies = response.data;
                this.is_loading = false;
            })
        },
        searchOnEnter(e){
            if(e.key.toLowerCase() === 'enter'){
                if(this.query.length < 3){
                    this.$swal('Error', 'Masukan setidaknya 3 digit huruf untuk mencari nama perusahaan', 'error')
                }else{
                    this.getData();
                }
            }
        }
    },
    computed: {

    }
}
</script>