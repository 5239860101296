<template>
    <aside id="sidebar" class="sidebar">
        <ul class="sidebar-nav" id="sidebar-nav">

        <li class="nav-item">
            <router-link class="nav-link" :to="{name:'Bendungan'}" inactive-class="collapsed">
                <img src="../assets/icon/sarpras-dashboard.png" alt="" style="width: 30px; padding-right: 5px;">
                <span>Overview</span>
            </router-link>
        </li>
        <li class="nav-item">
            <a class="nav-link collapsed" data-bs-target="#forms-nav" data-bs-toggle="collapse" href="#" aria-expanded="false">
                <img src="../assets/icon/sarpras-drawer.png" alt="" style="width: 30px; padding-right: 5px;">
                <span>Geomoss</span><i class="bi bi-chevron-down ms-auto"></i>
            </a>
            <ul id="forms-nav" class="nav-content collapse" data-bs-parent="#sidebar-nav" style="">
                <li>
                    <router-link :to="{name:'geomoss_index', params: {source: 'djuanda'}}">
                        <i class="bi bi-circle"></i><span>Djuanda</span>
                    </router-link>
                </li>
                <li>
                    <router-link :to="{name:'geomoss_index', params: {source: 'batutegi'}}">
                        <i class="bi bi-circle"></i><span>Batu Tegi</span>
                    </router-link>
                </li>
            </ul>
        </li>
        </ul>
    </aside>
</template>

<script>
    export default {
        name: 'SidebarBendungan',
        data(){
            return{
                route: ''
            }
        },
    }
</script>