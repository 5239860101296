<template>
    <aside id="sidebar" class="sidebar">
        <ul class="sidebar-nav" id="sidebar-nav">

        <li class="nav-item">
            <router-link class="nav-link" :to="{name:'SistarumMap'}" inactive-class="collapsed">
                <img src="../assets/icon/sarpras-dashboard.png" alt="" style="width: 30px; padding-right: 5px;">
                <span>Overview</span>
            </router-link>
        </li>
        
        </ul>
    </aside>
</template>

<script>
    export default {
        name: 'SidebarSistarum',
        data(){
            return{
                route: ''
            }
        },
    }
</script>