<template>

    <div id="dashboard">
        <loading :active.sync="is_loading" 
            :can-cancel="true" 
            :is-full-page="true">
        </loading>

        <!-- ====== Navbar ====== -->
        <Navbar />

        <!-- ====== Content ====== -->
        <main class="main" style="margin-top: 100px;">
            <section class="section dashboard">
                <div class="container">
                    <!-- ROW name -->
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="float-left">
                                <h3><strong>Halo, {{ name }}</strong></h3>
                                <p>Silakan pilih aplikasi yang ingin anda coba hari ini</p> 
                            </div>
                        </div>
                    </div>

                    <VueSlickCarousel :autoplay="true" :slidesToShow="3" :slidesToScroll="3" :arrows="true" class="menu-carousel">
                        <div class="pe-4" v-if="access.indexOf('sarpras') > -1 || role == 'admin'">
                            <router-link to="/sarpras/dashboard" type="button" class="btn btn-outline-info d-flex align-items-center">
                                <img src="../../assets/logo-sarpras.png" class="menu-icon me-3">
                                <strong>Sarpras</strong>
                            </router-link>
                        </div>
                        <div class="pe-4" v-if="access.indexOf('operasi') > -1 || role == 'admin'">
                            <router-link to="/operation/dashboard" type="button" class="btn btn-outline-info d-flex align-items-center">
                                <img src="../../assets/logo-operasi.png" class="menu-icon me-3">
                                <strong>Operasi</strong>
                            </router-link>
                        </div>
                        <!-- <div class="pe-4" v-if="access.indexOf('monitoring') > -1 || role == 'admin'">
                            <a class="btn btn-outline-info d-flex align-items-center" :href="'http://pm.jasatirta2.id/token_login?token='+this.token">
                                <img src="../../assets/logo-monitoring.png" alt="" class="menu-icon me-3">
                                <strong>Monitoring O&P Sumber Daya Air</strong>
                            </a>
                        </div> -->
                        <div class="pe-4" v-if="access.indexOf('skema') > -1 || role == 'admin'">
                            <router-link to="/skema" type="button" class="btn btn-outline-info d-flex align-items-center">
                                <img src="../../assets/logo-skema.png" class="menu-icon me-3">
                                <strong>Skema Distribusi Air</strong>
                            </router-link>
                        </div>
                        <div class="pe-4" v-if="access.indexOf('spasial') > -1 || role == 'admin'">
                            <router-link to="/spatial" type="button" class="btn btn-outline-info d-flex align-items-center">
                                <img src="../../assets/logo-map.png" class="menu-icon me-3">
                                <strong>Spasial</strong>
                            </router-link>
                        </div>
                        <div class="pe-4" v-if="access.indexOf('telemeteri') > -1 || role == 'admin'">
                            <router-link to="/telemeteri" type="button" class="btn btn-outline-info d-flex align-items-center">
                                <img src="../../assets/logo-telemeteri.png" class="menu-icon me-3">
                                <strong>Telemeteri</strong>
                            </router-link>
                        </div>
                        <div class="pe-4" v-if="access.indexOf('cctv') > -1 || role == 'admin'">
                            <router-link to="/cctv" type="button" class="btn btn-outline-info d-flex align-items-center">
                                <img src="../../assets/logo-cctv.png" class="menu-icon me-3">
                                <strong>CCTV</strong>
                            </router-link>
                        </div>
                        <div class="pe-4" v-if="access.indexOf('plta') > -1 || role == 'admin'">
                            <router-link to="/plta" type="button" class="btn btn-outline-info d-flex align-items-center">
                                <img src="../../assets/logo-plta.png" class="menu-icon me-3">
                                <strong>PLTA</strong>
                            </router-link>
                        </div>
                        <div class="pe-4" v-if="access.indexOf('bendungan') > -1 || role == 'admin'">
                            <router-link to="/bendungan" type="button" class="btn btn-outline-info d-flex align-items-center">
                                <img src="../../assets/logo-bendungan.png" class="menu-icon me-3">
                                <strong>Bendungan</strong>
                            </router-link>
                        </div>
                        <div class="pe-4" v-if="access.indexOf('cuaca') > -1 || role == 'admin'">
                            <router-link to="/bmkg" type="button" class="btn btn-outline-info d-flex align-items-center">
                                <img src="../../assets/logo-bmkg.png" class="menu-icon me-3">
                                <strong>Cuaca BMKG</strong>
                            </router-link>
                        </div>
                        <div class="pe-4" v-if="access.indexOf('scada') > -1 || role == 'admin'">
                            <router-link to="/scada/dashboard" type="button" class="btn btn-outline-info d-flex align-items-center">
                                <img src="../../assets/logo-scada.png" class="menu-icon me-3">
                                <strong>SCADA</strong>
                            </router-link>
                        </div>
                        <div class="pe-4" v-if="access.indexOf('watermeter') > -1 || role == 'admin'">
                            <router-link :to="{name: 'WaterMeterDashboard'}" type="button" class="btn btn-outline-info d-flex align-items-center">
                                <img src="../../assets/logo-water-meter.png" class="menu-icon me-3">
                                <strong>Water Meter</strong>
                            </router-link>
                        </div>
                        <div class="pe-4" v-if="access.indexOf('sistarum') > -1 || role == 'admin'">
                            <router-link to="/sistarum" type="button" class="btn btn-outline-info d-flex align-items-center">
                                <img src="../../assets/logo-sistarum.png" class="menu-icon me-3">
                                <strong>Sistarum</strong>
                            </router-link>
                        </div>
                    </VueSlickCarousel>

                    <!-- ROW 2 -->
                    <!-- <div class="row mt-5" v-if="access.indexOf('referensi') > -1 || access.indexOf('user') > -1 || role == 'admin'">
                        <div class="col-sm-12">
                            <p class="text-black"><strong>Admin Tools</strong></p> 
                        </div>
                    </div> -->

                    <!-- ROW 2 Button -->
                    <!-- <div class="row">
                        <div class="col-6 col-sm-4" v-if="access.indexOf('referensi') > -1 || role == 'admin'">
                            <router-link to="/referensi/organisasi/index" class="btn btn-outline-info">
                                <div class="row">
                                    <div class="col-6 col-lg-4 col-xl-3 mt-3 menu-icon">
                                        <img src="../../assets/logo-referensi.png" alt="" style="width: 70px;">
                                    </div>
                                    <div class="col-6 col-lg-4 col-xl-3 min-vh-50 d-flex align-items-center justify-content-center mt-3 menu-text">
                                        <strong>Referensi</strong>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                        <div class="col-6 col-sm-4" v-if="access.indexOf('user') > -1 || role == 'admin'">
                            <router-link to="/users/index" type="button" class="btn btn-outline-info">
                                <div class="row">
                                    <div class="col-6 col-lg-4 col-xl-3 mt-3 menu-icon">
                                        <img src="../../assets/logo-users.png" alt="" style="width: 70px;">
                                    </div>
                                    <div class="col-6 col-lg-4 col-xl-3 min-vh-50 d-flex align-items-center justify-content-center mt-3 menu-text">
                                        <strong>Users</strong>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div> -->

                    <!-- ROW 3 -->
                    <div class="row mt-5">
                        <div class="col-sm-12">
                            <p class="text-black"><strong>Feed</strong></p> 
                        </div>
                    </div>

                    <!-- ===== GRAPH 1 =====-->
                    <div class="row">
                        <!-- Card 1 -->
                        <div class="col-lg-12 col-xl-6">
                            <div class="card" style="min-height: 500px;">
                                <div class="card-body">
                                    <!-- Title -->
                                    <p class="card-title text-black">
                                        <img src="../../assets/icon/line-graph-icon.png" alt="" style="width: 40px;">
                                        <strong>TMA Waduk Ir. H. Djuanda (mdpl)</strong>
                                    </p>
                                    <line-chart :chart-data="charts" :options="chart_options"></line-chart>
                                </div>
                            </div>
                        </div>
                        <!-- Card 2 -->
                        <div class="col-lg-12 col-xl-6">
                            <div class="card" style="height: 500px;">
                                <div class="card-body">
                                    <!-- Graph -->
                                    <p class="card-title text-black">
                                        <img src="../../assets/icon/bar-graph-icon.png" alt="" style="width: 40px;">
                                        <strong>Debit Air ke PAB (m<sup>3</sup>/detik)</strong>
                                    </p>
                                    <div style="height: 380px" class="overflow-auto">
                                        <table class="table table-sm datatable table-responsive">
                                            <thead>
                                                <tr>
                                                    <th width="15%">Syphon Bekasi</th>
                                                    <th width="15%">
                                                        <span class="bg-warning border border-light rounded-circle" style="width:12px;height:12px;display:inline-block;background-color: #FF642E!important">
                                                            <span class="visually-hidden">&nbsp;</span>
                                                        </span>
                                                        Buaran
                                                    </th>
                                                    <th width="20%">
                                                        <span class="bg-success border border-light rounded-circle" style="width:12px;height:12px;display:inline-block;background-color:#9CD326!important">
                                                            <span class="visually-hidden">&nbsp;</span>
                                                        </span>
                                                        Pulogadung</th>
                                                    <th width="25%">
                                                        <span class="bg-info border border-light rounded-circle" style="width:12px;height:12px;display:inline-block">
                                                            <span class="visually-hidden">&nbsp;</span>
                                                        </span>
                                                        Pejompongan</th>
                                                    <th>Grafik</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="index in debit_pab.syphon_bekasi.length" :key="index">
                                                    <td>{{ debit_pab.syphon_bekasi[index] ? debit_pab.syphon_bekasi[index][1]:0 }}</td>
                                                    <td>{{ debit_pab.buaran[index] ? debit_pab.buaran[index][1]:0 }}</td>
                                                    <td>{{ debit_pab.pulogadung[index] ? debit_pab.pulogadung[index][1]:0 }}</td>
                                                    <td>{{ debit_pab.pejompongan[index] ? debit_pab.pejompongan[index][1]:0 }}</td>
                                                    <td style="vertical-align:middle">
                                                        <div class="progress" style="height:10px">
                                                            <div
                                                                class="progress-bar bg-warning"
                                                                role="progressbar" 
                                                                :style="'width: '+getDebitPercentage(debit_pab, index, 0)+'%; background-color: #FF642E!important'"
                                                                :aria-valuenow="getDebitPercentage(debit_pab, index, 0)" 
                                                                aria-valuemin="0" 
                                                                aria-valuemax="100"></div>
                                                            <div
                                                                class="progress-bar bg-success"
                                                                role="progressbar"
                                                                :style="'width: '+getDebitPercentage(debit_pab, index, 1)+'%;background-color:#9CD326!important'" 
                                                                :aria-valuenow="getDebitPercentage(debit_pab, index, 1)" 
                                                                aria-valuemin="0" 
                                                                aria-valuemax="100"></div>
                                                            <div 
                                                                class="progress-bar bg-info" 
                                                                role="progressbar" 
                                                                :style="'width: '+getDebitPercentage(debit_pab, index, 2)+'%'" 
                                                                :aria-valuenow="getDebitPercentage(debit_pab, index, 2)" 
                                                                aria-valuemin="0" 
                                                                aria-valuemax="100"></div>
                                                        </div>
                                                        {{ (debit_pab.syphon_bekasi[index] ? debit_pab.syphon_bekasi[index][0]:0) | formatTime}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- GRAPH ROW 2 -->
                    <div class="row">
                        <div class="col-lg-12 col-xl-8">
                            <div class="card">
                                <div class="card-body" style="overflow: auto">
                                    <p class="card-title">
                                        <img src="../../assets/icon/bar-graph-icon.png" alt="" style="width: 40px;">
                                        <strong>Air Waduk Ir. Djuanda (m<sup>3</sup>/detik)</strong>
                                    </p>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <table class="table table-hover table-responsive">
                                                <thead>
                                                    <tr>
                                                        <th width="20%"><span class="bg-warning border border-light rounded-circle" style="width:12px;height:12px;display:inline-block;background-color: #579BFC!important">
                                                            <span class="visually-hidden">&nbsp;</span>
                                                        </span> Air Masuk</th>
                                                        <th width="20%"><div class=""></div> Air Keluar Cirata</th>
                                                        <th width="20%"><div class=""></div> Air Masuk Lokal</th>
                                                        <th width="20%"><span class="bg-warning border border-light rounded-circle" style="width:12px;height:12px;display:inline-block;background-color: #FF642E!important">
                                                            <span class="visually-hidden">&nbsp;</span>
                                                        </span> Air Keluar Waduk</th>
                                                        <th width="20%"><div class=""></div> Air Keluar Turbin</th>
                                                        <th width="20%"><div class=""></div> HJV</th>
                                                        <th width="20%"><div class=""></div> Limpasan</th>
                                                        <th><center>Graph</center></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr class="fw-bold">
                                                        <td>{{ awd.am[0] ? awd.am[0][1]:'' }}</td>
                                                        <td>{{ awd.akc[0] ? awd.akc[0][1]:'' }}</td>
                                                        <td>{{ awd.aml[0] ? awd.aml[0][1]:''  }}</td>
                                                        <td>{{ awd.akw[0] ? awd.akw[0][1]:''  }}</td>
                                                        <td>{{ awd.aktb[0] ? awd.aktb[0][1]:''  }}</td>
                                                        <td>{{ awd.hjv[0] ? awd.hjv[0][1]:''  }}</td>
                                                        <td>{{ awd.limpas[0] ? awd.limpas[0][1]:''  }}</td>
                                                        <td class="text-center">
                                                            <peity :type="'bar'" :options="{ 'fill': ['#579BFC', '#FF642E']}" :data="awd.am[0][1]+','+awd.akw[0][1]"></peity>
                                                            <br>
                                                            <small>{{ awd.akw[0][0] | formatShortDate}}</small>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{{ awd.am[1] ? awd.am[1][1]:'' }}</td>
                                                        <td>{{ awd.akc[1] ? awd.akc[1][1]:'' }}</td>
                                                        <td>{{ awd.aml[1] ? awd.aml[1][1]:'' }}</td>
                                                        <td>{{ awd.akw[1] ? awd.akw[1][1]:'' }}</td>
                                                        <td>{{ awd.aktb[1] ? awd.aktb[1][1]:'' }}</td>
                                                        <td>{{ awd.hjv[1] ? awd.hjv[1][1]:'' }}</td>
                                                        <td>{{ awd.limpas[1] ? awd.limpas[1][1]:'' }}</td>
                                                        <td class="text-center">
                                                            <peity :type="'bar'" :options="{ 'fill': ['#579BFC', '#FF642E']}" :data="awd.am[1][1]+','+awd.akw[1][1]"></peity>
                                                            <br>
                                                            <small>{{ awd.akw[1][0] | formatShortDate}}</small>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{{ awd.am[2] ? awd.am[2][1]:'' }}</td>
                                                        <td>{{ awd.akc[2] ? awd.akc[2][1]:'' }}</td>
                                                        <td>{{ awd.aml[2] ? awd.aml[2][1]:'' }}</td>
                                                        <td>{{ awd.akw[2] ? awd.akw[2][1]:'' }}</td>
                                                        <td>{{ awd.aktb[2] ? awd.aktb[2][1]:'' }}</td>
                                                        <td>{{ awd.hjv[2] ? awd.hjv[2][1]:'' }}</td>
                                                        <td>{{ awd.limpas[2] ? awd.limpas[2][1]:'' }}</td>
                                                        <td class="text-center">
                                                            <peity :type="'bar'" :options="{ 'fill': ['#579BFC', '#FF642E']}" :data="awd.am[2][1]+','+awd.akw[2][1]"></peity>
                                                            <br>
                                                            <small>{{ awd.akw[2][0] | formatShortDate}}</small>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-xl-4">
                            <div class="card">
                                <div class="card-body">
                                    <p class="card-title">
                                        <img src="../../assets/icon/bar-graph-icon.png" alt="" style="width: 40px;">
                                        <strong>Bendung Curug (m<sup>3</sup>/detik)</strong>
                                    </p>
                                    <!-- TMA -->
                                    <div class="row">
                                        <div class="col-md-8">{{ curug_tma.title }}</div>
                                        <div class="col-md-4"><span class="float-end"><b>{{ curug_tma.data[curug_tma.data.length - 1] }}</b></span></div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12" ref="spark_wrap">
                                            <sparkline :width="sparkWidth">
                                                <sparklineBar
                                                :data="curug_tma.data"
                                                :margin="2"
                                                :limit="curug_tma.data.length"
                                                :styles="spark_style"
                                                :refLineStyles="{}" />
                                            </sparkline>
                                        </div>
                                    </div>

                                    <!-- VOLUME -->
                                    <div class="row mt-3">
                                        <div class="col-md-8">{{ curug_volume.title }}</div>
                                        <div class="col-md-4"><span class="float-end"><b>{{ curug_volume.data[curug_volume.data.length - 1] }}</b></span></div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12" ref="spark_wrap">
                                            <sparkline :width="sparkWidth">
                                                <sparklineBar
                                                :data="curug_volume.data"
                                                :margin="2"
                                                :limit="curug_volume.data.length"
                                                :styles="spark_style"
                                                :refLineStyles="{}" />
                                            </sparkline>
                                        </div>
                                    </div>

                                    <!-- Q1 -->
                                    <div class="row mt-3">
                                        <div class="col-md-8">{{ curug_q1.title }}</div>
                                        <div class="col-md-4"><span class="float-end"><b>{{ curug_q1.data[curug_q1.data.length - 1] }}</b></span></div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12" ref="spark_wrap">
                                            <sparkline :width="sparkWidth">
                                                <sparklineBar
                                                :data="curug_q1.data"
                                                :margin="2"
                                                :limit="curug_q1.data.length"
                                                :styles="spark_style"
                                                :refLineStyles="{}" />
                                            </sparkline>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- ===== TABLE 3 =====-->
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card">
                                <div class="card-body" style="overflow: auto">
                                    <!-- Title -->
                                    <p class="card-title text-black">
                                        <img src="../../assets/icon/table-icon.png" alt="" style="width: 40px;">
                                        <strong>Data Bendung</strong>
                                    </p>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <table class="table table-hover table-responsive">
                                                <thead>
                                                    <tr>
                                                        <th>Bendung</th>
                                                        <th v-for="(heading, key) in box_data_bendung.heading" :key="key" @dblclick="setEditable($event)" @keypress="updateHeading($event)" @keydown="removeEditable($event)" :id="key">{{ heading }}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>Q Sungai</th>
                                                        <td v-for="(value, key) in box_data_bendung.data.row_1" :key="key" :id="key" class="data-column">
                                                            <span>{{ value }}</span>
                                                            <a href="#" class="btn btn-success btn-sm ms-1 edit-btn" @click.prevent="showSelect(key)"><i class="fa fa-pen"></i></a>
                                                            <select style="width: 100px; display: none" class="mb-1">
                                                                <optgroup v-for="(periodic, location_name) in periodics" :key="periodic.location_id" :label="location_name">
                                                                    <option v-for="periodic_data in periodic" :value="periodic_data.id" :key="periodic_data.id">{{ periodic_data.name }}</option>
                                                                </optgroup>
                                                            </select>
                                                            <a href="#" class="btn btn-primary btn-sm update-btn" @click.prevent="updateColumn(key)"><i class="fa fa-save"></i></a>
                                                            <a href="#" class="btn btn-danger btn-sm ms-1 update-btn" @click.prevent="hideSelect(key)"><i class="fa fa-times"></i></a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>Q Tersedia</th>
                                                        <td v-for="(value, key) in box_data_bendung.data.row_2" :key="key" :id="key" class="data-column">
                                                            <span>{{ value }}</span>
                                                            <a href="#" class="btn btn-success btn-sm ms-1 edit-btn" @click.prevent="showSelect(key)"><i class="fa fa-pen"></i></a>
                                                            <select style="width: 100px; display: none" class="mb-1">
                                                                <optgroup v-for="(periodic, location_name) in periodics" :key="periodic.location_id" :label="location_name">
                                                                    <option v-for="periodic_data in periodic" :value="periodic_data.id" :key="periodic_data.id">{{ periodic_data.name }}</option>
                                                                </optgroup>
                                                            </select>
                                                            <a href="#" class="btn btn-primary btn-sm update-btn" @click.prevent="updateColumn(key)"><i class="fa fa-save"></i></a>
                                                            <a href="#" class="btn btn-danger btn-sm ms-1 update-btn" @click.prevent="hideSelect(key)"><i class="fa fa-times"></i></a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>Q Dimanfaatkan</th>
                                                        <td v-for="(value, key) in box_data_bendung.data.row_3" :key="key" :id="key" class="data-column">
                                                            <span>{{ value }}</span>
                                                            <a href="#" class="btn btn-success btn-sm ms-1 edit-btn" @click.prevent="showSelect(key)"><i class="fa fa-pen"></i></a>
                                                            <select style="width: 100px; display: none" class="mb-1">
                                                                <optgroup v-for="(periodic, location_name) in periodics" :key="periodic.location_id" :label="location_name">
                                                                    <option v-for="periodic_data in periodic" :value="periodic_data.id" :key="periodic_data.id">{{ periodic_data.name }}</option>
                                                                </optgroup>
                                                            </select>
                                                            <a href="#" class="btn btn-primary btn-sm update-btn" @click.prevent="updateColumn(key)"><i class="fa fa-save"></i></a>
                                                            <a href="#" class="btn btn-danger btn-sm ms-1 update-btn" @click.prevent="hideSelect(key)"><i class="fa fa-times"></i></a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>Q Limpasan</th>
                                                        <td v-for="(value, key) in box_data_bendung.data.row_4" :key="key" :id="key" class="data-column">
                                                            <span>{{ value }}</span>
                                                            <a href="#" class="btn btn-success btn-sm ms-1 edit-btn" @click.prevent="showSelect(key)"><i class="fa fa-pen"></i></a>
                                                            <select style="width: 100px; display: none" class="mb-1">
                                                                <optgroup v-for="(periodic, location_name) in periodics" :key="periodic.location_id" :label="location_name">
                                                                    <option v-for="periodic_data in periodic" :value="periodic_data.id" :key="periodic_data.id">{{ periodic_data.name }}</option>
                                                                </optgroup>
                                                            </select>
                                                            <a href="#" class="btn btn-primary btn-sm update-btn" @click.prevent="updateColumn(key)"><i class="fa fa-save"></i></a>
                                                            <a href="#" class="btn btn-danger btn-sm ms-1 update-btn" @click.prevent="hideSelect(key)"><i class="fa fa-times"></i></a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </main>
    </div>
</template>

<script>
    import axios from 'axios' 
    import Navbar from '../../components/Navbar.vue'
    import LineChart from '../../components/chart/LineChart.vue'
    import Sparkline from 'vue-sparklines'
    import Peity from 'vue-peity';
    import moment from 'moment'
    import Loading from 'vue-loading-overlay'
    import VueSlickCarousel from 'vue-slick-carousel'
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    // optional style for arrows & dots
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

    export default{
        name: 'Dashboard',
        components:{
            Navbar,
            LineChart,
            Sparkline,
            Peity,
            Loading,
            VueSlickCarousel
        },
        data(){
            return{
                auto_ref: null,
                is_loading: false,
                name: '',
                token:'',
                access: [],
                chart_styles: {
                    height: "300px"
                },
                chart_options: {
                    responsive: true,
                    maintainAspectRatio: false
                },
                charts: {},
                debit_pab: {
                    buaran: [],
                    pulogadung: [],
                    pejompongan: [],
                    syphon_bekasi: []
                },
                curug_q1: {data: []},
                curug_tma: {data: []},
                curug_volume: {data: []},
                box_data_bendung: {
                    heading: {},
                    data: {}
                },
                awd: {
                    am: [],
                    akc: [],
                    aml: [],
                    akw: [],
                    aktb: [],
                    hjv: [],
                    limpas: []
                },
                spMargin4: 1,
                spark_style: {
                    fill: '#54a5ff',
                },
                spLineStyles4: {
                    stroke: '#d14'
                },
                spRefLineStyles4: {
                },
                len: '',
                index_array: 1,
                sparkWidth: 0,
                role: '',
                periodics: []
            }
        },
        methods: {
            getDebitPercentage(debit_pab, index, i){
                const total = parseFloat(debit_pab.buaran[index] ? debit_pab.buaran[index][1]:0) + parseFloat(debit_pab.pulogadung[index] ? debit_pab.pulogadung[index][1]:0) + parseFloat(debit_pab.pejompongan[index] ? debit_pab.pejompongan[index][1]:0);
                let value = 0;
                switch(i){
                    case 0:
                        var buaran = parseFloat(debit_pab.buaran[index] ? debit_pab.buaran[index][1]:0);
                        value = buaran / total * 100;
                        break;

                    case 1:
                        var pulogadung = parseFloat(debit_pab.pulogadung[index] ? debit_pab.pulogadung[index][1]:0);
                        value = pulogadung / total * 100;
                        break;

                    case 2:
                        var pejompongan = parseFloat(debit_pab.pejompongan[index] ? debit_pab.pejompongan[index][1]:0);
                        value = pejompongan / total * 100;
                        break;
                }

                return value;
            },
            getData(){
                this.name = localStorage.getItem('name');
                this.token = localStorage.getItem('token');
                this.is_loading = true
                document.body.className = 'dashboard';

                // this.charts = 
                axios.get(this.$endpoints.operasi + 'dashboard/tma_djuanda?token=' + this.token)
                .then(response => {
                    this.charts = response.data.data
                    this.is_loading = false
                });

                axios.get(this.$endpoints.operasi + 'dashboard/bendung_curug?token=' + this.token)
                .then((response) =>{
                    let width = this.$refs.spark_wrap?.clientWidth;
                    this.sparkWidth = width - 30;

                    this.curug_q1 = response.data.q1;
                    this.curug_q1.data = response.data.q1.data.map(v => v[1]);

                    this.curug_tma = response.data.tma;
                    this.curug_tma.data = response.data.tma.data.map(v => v[1]);

                    this.curug_volume = response.data.volume;
                    this.curug_volume.data = response.data.volume.data.map(v => v[1]);
                });

                axios.get(this.$endpoints.operasi + 'dashboard/data_bendung?token=' + this.token)
                .then(response => {
                    this.box_data_bendung = response.data
                });

                axios.get(this.$endpoints.operasi + 'dashboard/waduk_djuanda?token=' + this.token)
                .then(response => {
                    this.awd = response.data
                });

                axios.get(this.$endpoints.operasi + 'dashboard/debit_pab?token=' + this.token)
                .then(response => {
                    this.debit_pab = response.data
                });
            },
            getPeriodicData(){
                axios.get(this.$endpoints.reference+'periodics/all?token='+this.token)
                .then(response =>{
                    this.periodics = response.data;
                });
            },
            setEditable(e){
                e.target.contentEditable = true;
            },
            removeEditable(e){
                if(e.keyCode == 27){
                    e.target.contentEditable = false;
                }
            },
            updateHeading(e){
                if(e.keyCode == 13){
                    const k = e.target.id;
                    const v = e.target.textContent.trim();

                    e.target.contentEditable = false;
                    this.updateOption(k, v);
                }
            },
            showSelect(target){
                document.querySelector('#' + target).classList.add('show-select');
            },
            hideSelect(target){
                document.querySelector('#' + target).classList.remove('show-select');
            },
            updateColumn(key){
                const val = document.querySelector('#' + key + ' select').value;
                
                this.hideSelect(key);
                this.updateOption(key, val, () => {
                    this.is_loading = true;

                    axios.get(this.$endpoints.operasi + 'dashboard/data_bendung?token=' + this.token)
                    .then(response => {
                        this.is_loading = false;
                        this.box_data_bendung = response.data
                    });
                });
            },
            updateOption(k, v, cb){
                this.is_loading = true;

                let fd = new FormData();
                fd.append('key', k);
                fd.append('val', v);

                axios.post(this.$endpoints.operasi + 'update_option?token=' + this.token, fd)
                .then(() => {
                    this.is_loading = false;
                    cb();
                })
                .catch(e => {
                    console.log(e);
                });
            }
        },
        filters:{
            formatTime(value){
                return moment(value).format("DD MMM YYYY HH:mm")
            },
            formatShortDate(value){
                return moment(value).format("DD MMM YYYY")
            }
        },
        mounted(){
            this.getData();
            this.getPeriodicData();
            this.token = localStorage.getItem('token');
            this.role = localStorage.getItem('role');
            this.access = JSON.parse(localStorage.getItem('module_access'));

            this.auto_ref = setInterval(() => {
                // this.$toasted.show('refresh data...', {
                //     duration: 3000
                // });
                this.getData();
            }, this.$refresh_duration);
        },
        unmounted(){
            console.log('clearing timeout');
            clearTimeout(this.auto_ref);
        }
    }
</script>

<style scoped>

    body {
        font-family: "Open Sans", sans-serif;
        background: #f6f9ff!important;
        color: #444444;
    }

    .btn-info{
        border-radius: 10px;
        width: 100px;
    }

    .menu-icon{
        width: 70px;
    }

    [contenteditable="false"]{
        border: 0!important;
    }

    .data-column a.edit-btn,
    .data-column a.update-btn{
        visibility: hidden;
    }

    .data-column:hover a.edit-btn{
        visibility: visible;
    }

    .show-select span, 
    .show-select a.edit-btn{
        display: none;
    }

    .show-select a.update-btn{
        visibility: visible;
        display: inline;
    }

    .show-select select{
        display: block!important;
    }
</style>

<style>
    .menu-carousel .slick-prev:before,
    .menu-carousel .slick-next:before{
        color: #000!important;
    }
</style>