<template>
    <div>
        <loading :active.sync="is_loading" :can-cancel="true" :is-full-page="true">
        </loading>
        <Navbar />
        <SidebarWm />
        <div id="main" class="main">
            <Breadcrumb :links="links" />
            <section class="section dashboard" style="min-height: 600px;">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card overflow-visible">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-3">
                                        <label for="">Jenis</label>
                                        <select class="form-select" v-model="interval">
                                            <option value="d">Harian</option>
                                            <option value="m">Bulanan</option>
                                            <option value="y">Tahunan</option>
                                        </select>
                                    </div>
                                    <div class="col-md-3">
                                        <label for="">Tanggal mulai</label>
                                        <datepicker v-model="date_from" placeholder="Select date"
                                            input-class="form-control" :minimum-view="minview"
                                            :format="customFormatter"></datepicker>
                                    </div>
                                    <div class="col-md-3">
                                        <label for="">Tanggal akhir</label>
                                        <datepicker v-model="date_to" placeholder="Select date"
                                            input-class="form-control" :minimum-view="minview"
                                            :format="customFormatter"></datepicker>
                                    </div>
                                    <div class="col-md-3">
                                        <label for="">&nbsp;</label><br>
                                        <button class="btn btn-primary" @click="getData"><i class="fa fa-filter"></i> filter</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body">
                                <p>Total pemakaian air bulan berjalan <b>{{ company.name }}</b></p>
                                <h4 style="font-size: 38px" class="text-danger" v-if="parseFloat(total_consumption) > parseFloat(company.threshold)">
                                    {{ total_consumption }} / {{ company.threshold == null ? '-':company.threshold }} m3
                                </h4>
                                <h4 style="font-size: 38px" v-else>
                                    {{ total_consumption }} / {{ company.threshold == null ? '-':company.threshold }} m3
                                </h4>
                            </div>
                        </div>
                        <div class="float-end pe-3 mb-3">
                            <a href="" class="btn me-2" :class="mode == 'graphic' ? 'btn-primary':'btn-outline-primary'" @click.prevent="mode = 'graphic'"><i class="fa fa-chart-bar"></i></a>
                            <a href="" class="btn" :class="mode == 'table' ? 'btn-primary':'btn-outline-primary'" @click.prevent="mode = 'table'"><i class="fa fa-table"></i></a>
                        </div>
                        <div class="clearfix"></div>
                        <div class="card" v-for="(wm, idx) in watermeter" :key="wm.id">
                            <div class="card-body">
                                <p><b>{{ wm.name }}</b></p>
                                <bar-chart :chart-data="wm.chart" :height="500" :options="chart_options" v-if="mode === 'graphic'"></bar-chart>
                                <template v-if="mode === 'table'">
                                    <a href="" class="btn btn-success mb-3" @click.prevent="downloadCsv(idx)"><i class="fa fa-download"></i> download CSV</a>
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Tanggal</th>
                                                <th>Total pemakaian</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="data in wm.data" :key="data.tanggal">
                                                <td width="15%">{{ customFormatter(data.tanggal) }}</td>
                                                <td>{{ data.total_consumption }} m3</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Breadcrumb from '../../components/Breadcrumb.vue'
import Navbar from '../../components/Navbar.vue'
import SidebarWm from '../../components/SidebarWm.vue'
import BarChart from '../../components/chart/BarChart.vue'
import Datepicker from 'vuejs-datepicker'
import axios from 'axios'
import moment from 'moment'

export default {
    components: {
        Navbar,
        Loading,
        Breadcrumb,
        SidebarWm,
        Datepicker,
        BarChart
    },
    data() {
        return {
            is_loading: false,
            links: [
                    {url: '/dashboard', text:'Home'}, 
                    {url: '/watermeter', text: 'Water Meter'}, 
                    {url: '#', text: 'Detail'}
                ],
            interval: 'd',
            minview: 'day',
            date_from: moment().format("YYYY-MM-01"),
            date_to: moment().format("YYYY-MM-DD"),
            mode: 'graphic',
            company: {},
            watermeter: [],
            total_consumption: 0,
            chart_options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [{
                        ticks: {
                            autoSkip: true,
                            maxTicksLimit: 6,
                            maxRotation: 0,
                            fontSize: 10
                        }
                    }],
                    yAxes: [{
                        display: true,
                        position: "left",
                        id: "y-axis-1",
                        scaleLabel: {
                            display: true,
                            labelString: "m3"
                        }
                    }]
                }
            }
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            this.is_loading = true;
            const { id } = this.$route.params;

            axios.get(`${this.$endpoints.watermeter}/companies/${id}`, {
                params: {
                    interval: this.interval,
                    date_from: moment(this.date_from).format('YYYY-MM-DD'),
                    date_to: moment(this.date_to).format('YYYY-MM-DD')
                }
            })
                .then(response => {
                    this.company = response.data.company;
                    this.watermeter = response.data.watermeter;
                    this.total_consumption = response.data.total_consumption;

                    this.watermeter.map(wm => {
                        wm.chart = {
                            labels: [],
                            datasets: [{
                                label: 'Jumlah pemakaian air (m3)',
                                backgroundColor: "rgba(75, 192, 192, 0.6)",
                                borderColor: "rgba(75, 192, 192, 1)",
                                borderWidth: 1,
                                data: []
                            }]
                        };

                        for (let data of wm.data) {
                            if (wm.chart.labels.indexOf(data.tanggal) == -1) {
                                wm.chart.labels.push(moment(data.tanggal).format('YYYY-MM-DD'));
                                wm.chart.datasets[0].data.push(parseFloat(data.total_consumption));
                            }
                        }

                        return wm;
                    })
                })
                .finally(() => {
                    this.is_loading = false;
                })
        },
        customFormatter(date) {
            switch (this.interval) {
                case "d":
                    return moment(date).format('DD MMM YYYY');

                case "m":
                    return moment(date).format('MMM YYYY');

                case "y":
                    return moment(date).format('YYYY');
            }
        },
        sanitizeName(name) {
            // Replace invalid characters with an underscore or any desired replacement
            return name
                .replace(/[/\\?%*:|"<>]/g, '_') // Replace reserved characters
                .replace(/\s+/g, '_')          // Optionally replace spaces with underscores
                .replace(/[^\x20-\x7E]/g, '') // Remove Non-Printable Characters
                .trim();                       // Trim leading and trailing whitespace
        },
        arrayToCSV(array) {
            const csvRows = [];
            
            // Get headers from the first object keys
            const headers = Object.keys(array[0]).reverse();
            csvRows.push(headers.join(',')); // join headers with commas

            // Loop through the array and create rows
            for (const row of array) {
                const values = headers.map(header => {
                    let escaped = ('' + row[header]).replace(/"/g, '\\"'); // escape double quotes
                    if(header == 'tanggal'){
                        escaped = moment(escaped).format("YYYY-MM-DD");
                    }else{
                        escaped += " m3"
                    }
                    return `"${escaped}"`; // wrap values in double quotes
                });
                csvRows.push(values.join(',')); // join values with commas
            }

            return csvRows.join('\n'); // join rows with new line
        },
        downloadCsv(idx){
            const wm = this.watermeter[idx];
            const csvString = this.arrayToCSV(wm.data);
            const blob = new Blob([csvString], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            const filename = `${this.sanitizeName(this.company.name)}-${this.sanitizeName(wm.name)}-${moment(this.date_from).format("YYYYMMDD")}-${moment(this.date_to).format("YYYYMMDD")}.csv`;

            a.setAttribute('hidden', '');
            a.setAttribute('href', url);
            a.setAttribute('download', filename);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    },
    watch: {
        interval(val) {
            switch (val) {
                case "d":
                    this.minview = 'day';
                    return;

                case "m":
                    this.minview = 'month';
                    return;

                case "y":
                    this.minview = 'year';
                    return;
            }
        }
    }
}
</script>